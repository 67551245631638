
import { Component, Vue } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import { CarIllegalInfo } from '@/types/carManage'

@Component({
  name: 'AddMaintain'
})
export default class extends Vue {
  private info: CarIllegalInfo = {
    projectId: '',
    vehicleId: '',
    vehicleType: '',
    violationTime: '',
    violationAddress: '',
    handleContent: '',
    brand: '',
    model: '',
    vehicleTypeName: ''
  }

  private submitShow = false
  private machineList: CarIllegalInfo[] = []

  private rules = {
    projectId: [{ required: true, message: '请选择所属项目', trigger: 'change' }],
    vehicleId: [{ required: true, message: '请选择车牌号', trigger: 'change' }],
    violationAddress: [
      { required: true, message: '请输入违章地点', trigger: 'blur' }
    ],
    violationTime: [
      { required: true, message: '请选择违章时间', trigger: 'change' }
    ]
  }

  private carTypeList = []

  get violationId () {
    return this.$route.params.id as string || ''
  }

  get normalProjectList () {
    return this.$store.state.normalProjectList
  }

  created () {
    this.violationId && this.getDetail(this.violationId)
    this.getCarTypeList()
  }

  getDetail (violationId: string) {
    this.$axios.get(this.$apis.carManage.selectYhVehicleViolationByViolationId, { violationId }).then((res) => {
      this.info = res
      this.getMachineList()
    })
  }

  // 车辆类型
  getCarTypeList () {
    this.$axios.get(this.$apis.common.selectDicByList, { dicType: 'vehicleType' }).then(res => {
      this.carTypeList = res.vehicleType || []
    })
  }

  // 项目改变
  changeProject (value: string) {
    this.info.vehicleId = ''
    this.info.vehicleTypeName = ''
    this.info.brand = ''
    this.info.model = ''

    value && this.getMachineList()
  }

  changeMachine (value: string) {
    const res = this.machineList.find((item) => item.vehicleId === value)
    if (res) {
      this.info.vehicleTypeName = res.vehicleTypeName
      this.info.brand = res.brand
      this.info.model = res.model
    }
  }

  // 车牌号
  getMachineList () {
    this.$axios.get(this.$apis.carManage.selectYhVehicleByPage, {
      projectId: this.info.projectId
    }).then((res) => {
      this.machineList = res.list || []
    })
  }

  saveForm () {
    ;(this.$refs.form as ElForm).validate((valid) => {
      if (valid) {
        this.submitShow = true
        const urls = this.$route.params.id
          ? this.$apis.carManage.updateYhVehicleViolation
          : this.$apis.carManage.insertYhVehicleViolation
        // 不需要传这三个参数
        delete this.info.vehicleTypeName
        delete this.info.brand
        delete this.info.model
        this.$axios
          .post(urls, this.info)
          .then(() => {
            this.$message.success('保存成功')
            this.$router.back()
          })
          .finally(() => {
            this.submitShow = false
          })
      }
    })
  }
}
